var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "mt-1" },
    [
      _c(
        "b-overlay",
        {
          attrs: { center: "", show: _vm.isLoading, rounded: "sm" },
          scopedSlots: _vm._u([
            {
              key: "overlay",
              fn: function () {
                return [
                  _c(
                    "div",
                    { staticClass: "text-center" },
                    [
                      _c("base-icon", {
                        attrs: { name: "loading", width: "35", height: "35" },
                      }),
                      _vm._v(" "),
                      _c("p", { attrs: { id: "cancel-label" } }, [
                        _vm._v("Operazione in corso..."),
                      ]),
                    ],
                    1
                  ),
                ]
              },
              proxy: true,
            },
          ]),
        },
        [
          !_vm.isLoading
            ? _c(
                "div",
                [
                  _c("validation-observer", {
                    ref: "observer",
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function ({ invalid, handleSubmit }) {
                            return [
                              _c(
                                "b-form",
                                {
                                  on: {
                                    submit: function ($event) {
                                      $event.preventDefault()
                                    },
                                  },
                                },
                                [
                                  _c(
                                    "b-card",
                                    {
                                      attrs: {
                                        header: "Dati Identificativi",
                                        "header-tag": "header",
                                      },
                                    },
                                    [
                                      _c(
                                        "b-card-text",
                                        [
                                          _c("b-row", [
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "form-group col-md-3",
                                              },
                                              [
                                                _c("base-input", {
                                                  attrs: {
                                                    name: "title",
                                                    vid: "title",
                                                    label: "Titolo",
                                                    placeholder:
                                                      "Inserisci un titolo",
                                                    rules: { required: true },
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.form[_vm.rep].title,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.form[_vm.rep],
                                                        "title",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "form[rep].title",
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "div",
                                              { staticClass: "col-md-3" },
                                              [
                                                _c("base-select", {
                                                  attrs: {
                                                    name: "type",
                                                    label: "Tipo",
                                                    options: _vm.types,
                                                    taggable: false,
                                                    multiple: false,
                                                    rules: { required: true },
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.form[_vm.rep].type,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.form[_vm.rep],
                                                        "type",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "form[rep].type",
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                          ]),
                                          _vm._v(" "),
                                          _c("b-row", [
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "form-group col-md-3",
                                              },
                                              [
                                                _c("base-select", {
                                                  attrs: {
                                                    name: "type",
                                                    label: "Seleziona Preset",
                                                    options: _vm.presets,
                                                    taggable: false,
                                                    multiple: false,
                                                  },
                                                  on: {
                                                    input: _vm.onSelectPreset,
                                                  },
                                                  model: {
                                                    value: _vm.form.preset,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.form,
                                                        "preset",
                                                        $$v
                                                      )
                                                    },
                                                    expression: "form.preset",
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "form-group col-md-3 mt-3 py-2",
                                              },
                                              [
                                                _c(
                                                  "b-button",
                                                  {
                                                    attrs: {
                                                      type: "button",
                                                      variant: "lisaweb",
                                                      size: "sm",
                                                    },
                                                    on: {
                                                      click: _vm.onLoadPreset,
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      "\n                    Carica Preset\n                  "
                                                    ),
                                                  ]
                                                ),
                                              ],
                                              1
                                            ),
                                          ]),
                                          _vm._v(" "),
                                          _c("b-row", [
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "form-group col-md-3",
                                              },
                                              [
                                                _c("base-input", {
                                                  attrs: {
                                                    name: "margin_left",
                                                    vid: "margin_left",
                                                    label:
                                                      "Margine sinistro (mm)",
                                                    placeholder:
                                                      "Inserisci margine sinistro",
                                                    type: "number",
                                                    rules: { required: true },
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.form[_vm.rep]
                                                        .margin_left,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.form[_vm.rep],
                                                        "margin_left",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "form[rep].margin_left",
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "form-group col-md-3",
                                              },
                                              [
                                                _c("base-input", {
                                                  attrs: {
                                                    name: "margin_right",
                                                    vid: "margin_right",
                                                    label:
                                                      "Margine destro (mm)",
                                                    placeholder:
                                                      "Inserisci Margine destro",
                                                    type: "number",
                                                    rules: { required: true },
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.form[_vm.rep]
                                                        .margin_right,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.form[_vm.rep],
                                                        "margin_right",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "form[rep].margin_right",
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "form-group col-md-3",
                                              },
                                              [
                                                _c("base-input", {
                                                  attrs: {
                                                    name: "margin_top",
                                                    vid: "margin_top",
                                                    label: "Margine alto (mm)",
                                                    placeholder:
                                                      "Inserisci margine alto",
                                                    type: "number",
                                                    rules: { required: true },
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.form[_vm.rep]
                                                        .margin_top,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.form[_vm.rep],
                                                        "margin_top",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "form[rep].margin_top",
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "form-group col-md-3",
                                              },
                                              [
                                                _c("base-input", {
                                                  attrs: {
                                                    name: "margin_down",
                                                    vid: "margin_down",
                                                    label: "Margine basso (mm)",
                                                    placeholder:
                                                      "Inserisci margine basso",
                                                    type: "number",
                                                    rules: { required: true },
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.form[_vm.rep]
                                                        .margin_bottom,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.form[_vm.rep],
                                                        "margin_bottom",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "form[rep].margin_bottom",
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                          ]),
                                          _vm._v(" "),
                                          _vm._l(
                                            _vm.editor_config,
                                            function (config, index) {
                                              return _c(
                                                "b-row",
                                                { key: index },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "mb-4 col-md-12",
                                                    },
                                                    [
                                                      _c("label", [
                                                        _vm._v(
                                                          _vm._s(config.title)
                                                        ),
                                                      ]),
                                                      _vm._v(" "),
                                                      _c("base-editor", {
                                                        attrs: {
                                                          height: config.height,
                                                          image_list:
                                                            _vm.images,
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.form[_vm.rep][
                                                              config.model
                                                            ],
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.form[_vm.rep],
                                                              config.model,
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "form[rep][config.model]",
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                ]
                                              )
                                            }
                                          ),
                                          _vm._v(" "),
                                          _c("b-row", [
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "form-group col-md-12",
                                              },
                                              [
                                                _c("base-textarea", {
                                                  attrs: {
                                                    vid: "css",
                                                    name: "css",
                                                    label: "Css",
                                                    placeholder:
                                                      "Inserisci css",
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.form[_vm.rep].css,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.form[_vm.rep],
                                                        "css",
                                                        $$v
                                                      )
                                                    },
                                                    expression: "form[rep].css",
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                          ]),
                                        ],
                                        2
                                      ),
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    { staticClass: "py-2 mb-4" },
                                    [
                                      _c(
                                        "b-button",
                                        {
                                          attrs: {
                                            type: "button",
                                            disabled: invalid,
                                            variant: "lisaweb",
                                            size: "sm",
                                          },
                                          on: {
                                            click: function ($event) {
                                              return handleSubmit(_vm.onSubmit)
                                            },
                                          },
                                        },
                                        [
                                          _vm._v(
                                            "\n              Salva\n            "
                                          ),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "b-button",
                                        {
                                          attrs: {
                                            type: "button",
                                            variant: "lisaweb",
                                            size: "sm",
                                          },
                                          on: { click: _vm.showPdfPreview },
                                        },
                                        [
                                          _vm._v(
                                            "\n              Preview\n            "
                                          ),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "b-button",
                                        {
                                          staticClass: "float-right",
                                          attrs: {
                                            variant: "lisaweb",
                                            size: "sm",
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.$router.back()
                                            },
                                          },
                                        },
                                        [
                                          _c("b-icon-chevron-double-left", {
                                            attrs: { "font-scale": "0.9" },
                                          }),
                                          _vm._v("Torna indietro"),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      1943184855
                    ),
                  }),
                ],
                1
              )
            : _vm._e(),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
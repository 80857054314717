<template>
  <div class="mt-1">
    <b-overlay center :show="isLoading" rounded="sm">
      <div v-if="!isLoading">
        <validation-observer ref="observer" v-slot="{ invalid, handleSubmit }">
          <b-form @submit.prevent="">
            <b-card header="Dati Identificativi" header-tag="header">
              <b-card-text>
                <b-row>
                  <div class="form-group col-md-3">
                    <base-input
                      name="title"
                      vid="title"
                      label="Titolo"
                      v-model="form[rep].title"
                      placeholder="Inserisci un titolo"
                      :rules="{ required: true }"
                    />
                  </div>
                  <div class="col-md-3">
                    <base-select
                      name="type"
                      label="Tipo"
                      v-model="form[rep].type"
                      :options="types"
                      :taggable="false"
                      :multiple="false"
                      :rules="{ required: true }"
                    />
                  </div>
                </b-row>
                <b-row>
                  <div class="form-group col-md-3">
                    <base-select
                      name="type"
                      label="Seleziona Preset"
                      v-model="form.preset"
                      :options="presets"
                      @input="onSelectPreset"
                      :taggable="false"
                      :multiple="false"
                    />
                  </div>
                  <div class="form-group col-md-3 mt-3 py-2">
                    <b-button
                      type="button"
                      variant="lisaweb"
                      @click="onLoadPreset"
                      size="sm"
                    >
                      Carica Preset
                    </b-button>
                  </div>
                </b-row>
                <b-row>
                  <div class="form-group col-md-3">
                    <base-input
                      name="margin_left"
                      vid="margin_left"
                      label="Margine sinistro (mm)"
                      v-model="form[rep].margin_left"
                      placeholder="Inserisci margine sinistro"
                      type="number"
                      :rules="{ required: true }"
                    />
                  </div>
                  <div class="form-group col-md-3">
                    <base-input
                      name="margin_right"
                      vid="margin_right"
                      label="Margine destro (mm)"
                      v-model="form[rep].margin_right"
                      placeholder="Inserisci Margine destro"
                      type="number"
                      :rules="{ required: true }"
                    />
                  </div>
                  <div class="form-group col-md-3">
                    <base-input
                      name="margin_top"
                      vid="margin_top"
                      label="Margine alto (mm)"
                      v-model="form[rep].margin_top"
                      placeholder="Inserisci margine alto"
                      type="number"
                      :rules="{ required: true }"
                    />
                  </div>
                  <div class="form-group col-md-3">
                    <base-input
                      name="margin_down"
                      vid="margin_down"
                      label="Margine basso (mm)"
                      v-model="form[rep].margin_bottom"
                      placeholder="Inserisci margine basso"
                      type="number"
                      :rules="{ required: true }"
                    />
                  </div>
                </b-row>
                <b-row v-for="(config, index) in editor_config" :key="index">
                  <div class="mb-4 col-md-12">
                    <label>{{ config.title }}</label>
                    <base-editor
                      :height="config.height"
                      :image_list="images"
                      v-model="form[rep][config.model]"
                    />
                  </div>
                </b-row>
                <b-row>
                  <div class="form-group col-md-12">
                    <base-textarea
                      vid="css"
                      name="css"
                      label="Css"
                      v-model="form[rep].css"
                      placeholder="Inserisci css"
                    />
                  </div>
                </b-row>
              </b-card-text>
            </b-card>
            <div class="py-2 mb-4">
              <b-button
                @click="handleSubmit(onSubmit)"
                type="button"
                :disabled="invalid"
                variant="lisaweb"
                size="sm"
              >
                Salva
              </b-button>
              <b-button
                type="button"
                variant="lisaweb"
                size="sm"
                @click="showPdfPreview"
              >
                Preview
              </b-button>
              <b-button
                @click="$router.back()"
                variant="lisaweb"
                size="sm"
                class="float-right"
                ><b-icon-chevron-double-left
                  font-scale="0.9"
                ></b-icon-chevron-double-left
                >Torna indietro</b-button
              >
            </div>
          </b-form>
        </validation-observer>
      </div>
      <template #overlay>
        <div class="text-center">
          <base-icon name="loading" width="35" height="35" />
          <p id="cancel-label">Operazione in corso...</p>
        </div>
      </template>
    </b-overlay>
  </div>
</template>

<script>
import ShortcutMixin from "@/mixins/ShortcutMixin";
import LisaComunicaMixin from "@/mixins/LisaComunicaMixin";
import BaseIcon from "@/components/BaseIcon";
import BaseSelect from "@/components/form/BaseSelect";
import BaseInput from "@/components/form/BaseInput";
import BaseTextarea from "@/components/form/BaseTextarea.vue";
import BaseEditor from "@/components/form/BaseEditor";

export default {
  mixins: [ShortcutMixin, LisaComunicaMixin],
  data() {
    return {
      isLoading: false,
      repository: "pdf",
      id: this.$route.params.id,
      exceptions: ["title", "type"],
      types: [
        { value: "AVV_SCAD", text: "Avvisi scadenza" },
        { value: "MKTG", text: "Marketing" },
        { value: "SOLL", text: "Solleciti" },
        { value: "REN_PRO", text: "Rendiconti" },
      ],
      images: [],
      presets: [],
      editor_config: [
        { title: "Header", height: 200, model: "header" },
        { title: "Body", height: 600, model: "body" },
        { title: "Ads", height: 200, model: "ads" },
        { title: "Footer", height: 200, model: "footer" },
      ],
      form: {
        preset: null,
        pdf: {
          title: null,
          type: null,
          backbottom: null,
          backtop: null,
          margin_left: null,
          margin_right: null,
          margin_top: null,
          margin_bottom: null,
          css: null,
          header: "",
          body: "",
          ads: "",
          footer: "",
        },
      },
    };
  },
  components: {
    BaseIcon,
    BaseInput,
    BaseSelect,
    BaseTextarea,
    BaseEditor,
  },
  methods: {
    onSubmit() {
      this.isLoading = true;
      this.store()
        .then(() => {
          this.isLoading = false;
          this.$showSnackbar({
            preset: "success",
            text: `Azione Completata: Template Pdf Creato`,
          });
          this.shortcut("lisacomunica_template", null, "#Pdf", "filterPdf");
        })
        .catch((error) => {
          let errMsg = this.$getErrorMessage(error);
          this.$showSnackbar({
            preset: "error",
            text: `${errMsg}`,
          });
          this.isLoading = false;
        });
    },
    setPresetOptions(presets) {
      this.presets = presets.map((preset) => {
        return {
          value: String(preset.id),
          text: preset.title,
          type: preset.type,
          margin_left: preset.margin_left,
          margin_right: preset.margin_right,
          margin_top: preset.margin_top,
          margin_bottom: preset.margin_bottom,
          css: preset.css,
          header: preset.header,
          footer: preset.footer,
          ads: preset.ads,
          body: preset.body,
        };
      });
    },
  },
  mounted() {
    this.isLoading = true;
    this.preset_all()
      .then((response) => {
        this.setPresetOptions(response.data.content);
        this.index("", "image")
          .then((response) => {
            this.setImages(response.data.content);
            this.isLoading = false;
          })
          .catch((error) => {
            let errMsg = this.$getErrorMessage(error);
            this.$showSnackbar({
              preset: "error",
              text: `${errMsg}`,
            });
            this.isLoading = false;
          });
      })
      .catch((error) => {
        let errMsg = this.$getErrorMessage(error);
        this.$showSnackbar({
          preset: "error",
          text: `${errMsg}`,
        });
        this.isLoading = false;
      });
  },
  computed: {
    rep() {
      return this.repository;
    },
  },
};
</script>
